import React from 'react';

import PricingTiers from './components/pricing-tiers';
import { Layout, SEO } from '../../layout';
import { Logos } from '../../components';
import { Block, Blockquote, Section } from '../../components/styled';

const Pricing: React.FC = () => (
  <Layout>
    <SEO
      prefix="Fidel API Pricing — "
      title="Simple pricing that scales with you"
      description="Power your applications with real-time payment data. Test for free, get going with low monthly minimums, and get bespoke discounts as your volume grows."
    />
    <Section centered maxWidth={1080}>
      <h1>Pricing that scales with you</h1>
      <p>
        Build on real-time transaction data at a fixed price that suits your
        business, with no hidden fees
      </p>
      <PricingTiers />
    </Section>
    <Section
      maxWidth={1360}
      padding="60px 25px"
      paddingTablet="100px 30px"
      paddingDesktop="100px 116px"
      bgColor="#f8f8f8"
    >
      <Block width="734px" margin="0 auto 60px">
        <Blockquote>
          “Working with Fidel API has been and continues to be fast and
          effortless. They understand our key challenges and aspirations, and
          are continuously finding innovative ways to allow our customers and
          partners to get the most from Avios.”
          <cite>
            Digital Product Manager, <strong>Avios</strong>
          </cite>
        </Blockquote>
      </Block>
      <Logos />
    </Section>
  </Layout>
);

export default Pricing;
