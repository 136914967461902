import { ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

import { Button } from '../../../components/styled';
import { Link } from '../../../components';
import {
  breakpoints,
  colours,
  fonts,
} from '../../../components/styled/variables';

const levitate = keyframes`
  from { transform: translateY(0) }
  to { transform: translateY(-8px) }
`;

export const Box = styled.div`
  border: 1px solid ${colours.border};
  border-radius: 10px;
  width: 100%;

  margin-bottom: 50px;
  padding: 68px 18px 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  > :last-child {
    margin-top: 22px;
  }

  &:hover {
    .gatsby-image-wrapper {
      animation: 1.2s ease-in-out ${levitate} infinite alternate;
      animation-fill-mode: forwards;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: calc(33.3% - 16px);
    max-width: 330px;
    height: 300px;
    padding: 72px 18px 32px;
    margin-bottom: 0;

    > :last-child {
      margin-top: auto;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 72px 45px 32px;
    width: calc(33.3% - 36px);
  }
`;

interface CTAProps {
  as: ReactNode;
}

export const CTA = styled(Button as any).attrs<CTAProps>(() => ({
  as: Link,
}))`
  ${({ colour }) => colour && `background: ${colours[colour]}`};
`;

interface IconProps {
  name: string;
}

export const Icon = styled.div<IconProps>`
  position: absolute;
  padding: 6px;
  bottom: calc(100% - 61px);

  background: url(${({ name }) => require(`../assets/shadow_${name}.png`)})
    no-repeat;
  background-position: bottom center;
`;

export const Tiers = styled.dl`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 40px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 60px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 100px;
  }
`;

export const Label = styled.dt`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
  z-index: 2;
  ${fonts.titles};

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

export const Text = styled.dd`
  line-height: 1.375;
  color: ${colours.darkGrey};
`;

export const Fineprint = styled.small`
  display: block;
  margin-top: 30px;
  color: ${colours.midGrey};
  text-align: left;
`;
