import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

import { Box, CTA, Fineprint, Icon, Label, Text, Tiers } from '../styled';
import { OverlayTrigger } from '../../../components';

interface Tier {
  label: string;
  text: string;
  ctaText: string;
  ctaId?: string;
  ctaColour?: string;
  link?: string;
  overlayLink?: string;
}

export const PricingTiers: React.FC = () => {
  const images = useStaticQuery(assetsQuery);

  const tiers: Tier[] = [
    {
      label: 'Free',
      text: 'Start building straight away with dummy data in a free test account',
      ctaId: 'dashboardSignUp-pricing-free',
      ctaText: 'Get started',
      ctaColour: 'orange',
      link: 'https://dashboard.fidel.uk/sign-up/',
    },
    {
      label: 'Scale',
      text: 'Go live for a small fee per transaction *',
      ctaId: 'contactSales-pricing-scale',
      ctaText: 'Talk to sales',
      overlayLink: 'contact_sales',
    },
    {
      label: 'Enterprise',
      text: 'Get bespoke volume-based pricing and dedicated support *',
      ctaId: 'contactSales-pricing-enterprise',
      ctaText: 'Talk to sales',
      overlayLink: 'contact_sales',
    },
  ];

  return (
    <>
      <Tiers>
        {tiers.map(
          ({
            label,
            text,
            ctaText,
            ctaId,
            link,
            overlayLink,
            ctaColour,
          }: Tier) => (
            <Box key={label}>
              <Icon name={label.toLowerCase()}>
                <GatsbyImage
                  image={
                    images[label.toLowerCase()].childImageSharp.gatsbyImageData
                  }
                  alt={`${label.toLowerCase()}’s image`}
                />
              </Icon>
              <Label>{label}</Label>
              <Text>{text}</Text>
              {link && (
                <CTA id={ctaId} to={link} colour={ctaColour}>
                  {ctaText}
                </CTA>
              )}
              {overlayLink && ctaId && (
                <OverlayTrigger target={overlayLink} id={ctaId}>
                  {ctaText}
                </OverlayTrigger>
              )}
            </Box>
          ),
        )}
      </Tiers>
      <Fineprint>* Minimums apply.</Fineprint>
    </>
  );
};

const assetsQuery = graphql`
  {
    free: file(relativePath: { eq: "pricing/cube_free@2x.png" }) {
      childImageSharp {
        gatsbyImageData(width: 32, placeholder: NONE, layout: FIXED)
      }
    }
    scale: file(relativePath: { eq: "pricing/cube_scale@2x.png" }) {
      childImageSharp {
        gatsbyImageData(width: 48, placeholder: NONE, layout: FIXED)
      }
    }
    enterprise: file(relativePath: { eq: "pricing/cube_enterprise@2x.png" }) {
      childImageSharp {
        gatsbyImageData(width: 64, placeholder: NONE, layout: FIXED)
      }
    }
  }
`;

export default PricingTiers;
